import React from "react"
import styled from "styled-components"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Mosaic from "./Mosaic"
import { Location } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"
import { mapReferenceToLink } from "../utils/mappers"
import Navlink from "./layout/Navlink"

export default function Navigation({ resources, blog }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          resourcesNav: contentfulNavbarCustom(
            identifier: { eq: "resources-navbar" }
          ) {
            variant
            links {
              __typename
              linkText
              linkReference {
                __typename
                ... on ContentfulExternalLink {
                  __typename
                  linkText
                  linkRef
                  icon {
                    svg {
                      content
                    }
                  }
                }
                ... on ContentfulPage {
                  __typename
                  slug
                  icon {
                    svg {
                      content
                    }
                  }
                }
              }
            }
          }
          blogNav: contentfulNavbarCustom(identifier: { eq: "blog-navbar" }) {
            variant
            links {
              __typename
              linkText
              linkReference {
                __typename
                ... on ContentfulExternalLink {
                  __typename
                  linkText
                  linkRef
                  icon {
                    svg {
                      content
                    }
                  }
                }
                ... on ContentfulPage {
                  __typename
                  slug
                  icon {
                    svg {
                      content
                    }
                  }
                }
              }
            }
          }
          allContentfulTag {
            edges {
              node {
                title
                slug
              }
            }
          }
        }
      `}
      render={(data) => {
        const { resourcesNav, blogNav, allContentfulTag } = data
        return (
          <Box>
            {blog && blogNav.variant === "blog" && (
              <BlogNavbar links={blogNav.links} tags={allContentfulTag.edges} />
            )}
            {resources && resourcesNav.variant === "mosaic" && (
              <MosaicNavbar links={resourcesNav.links} />
            )}
          </Box>
        )
      }}
    />
  )
}

const MosaicNavbar = ({ links }) => {
  return (
    <NavbarMosaic>
      <Mosaic />
      <Location>
        {({ location }) => {
          return (
            <ResourceNavbar py={4}>
              {links.map((link, index) => {
                const active = location.pathname.includes(
                  mapReferenceToLink(link.linkReference)
                )
                return (
                  <ResourceLink
                    key={index}
                    button
                    href={mapReferenceToLink(link.linkReference)}
                    active={active}
                    target={
                      link.linkReference.__typename === "ContentfulExternalLink"
                        ? "__blank"
                        : null
                    }
                    rel="noopener"
                  >
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: link.linkReference.icon.svg.content,
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      component={active ? "h1" : "h6"}
                      color="inherit"
                    >
                      {link.linkText}
                    </Typography>
                  </ResourceLink>
                )
              })}
            </ResourceNavbar>
          )
        }}
      </Location>
    </NavbarMosaic>
  )
}

const BlogNavbar = ({ links, tags }) => {
  return (
    <Box>
      <Location>
        {({ location }) => (
          <BlogStyledNavbar>
            {links.map((link, index) => {
              return (
                <Navlink
                  key={index}
                  data={link}
                  active={location.pathname === `/${link.linkReference.slug}`}
                />
              )
            })}
            {tags
              .sort((a, b) => (a.node.title > b.node.title ? 1 : -1))
              .map((tag, index) => {
                const tagData = {
                  linkReference: {
                    __typename: "ContentfulPage",
                    slug: `resources/blog/${tag.node.slug}`,
                  },
                  linkText: tag.node.title,
                }
                return (
                  <Navlink
                    key={index}
                    data={tagData}
                    active={location.pathname.includes(tag.node.slug)}
                  />
                )
              })}
          </BlogStyledNavbar>
        )}
      </Location>
    </Box>
  )
}

const NavbarMosaic = styled(Box)`
  ${({ theme }) => `
        position: relative;
        padding-top: 66px;
        ${theme.breakpoints.down("xs")} {
            display: none;
        }
    `}
`
const BlogStyledNavbar = styled(Box)`
  ${({ theme }) => `
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        box-shadow: ${theme.shadows[1]};
        & a {
            height: 50px;
        }
        ${theme.breakpoints.down("xs")} {
            padding-top: 66px;
        }
        ${theme.breakpoints.up("sm")} {
            flex-direction: row;
            height: 64px
            & a {
                height: auto;
            }
        }
    `}
`

const ResourceNavbar = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const ResourceLink = styled.a`
  ${({ theme }) => `
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${theme.palette.neutral.white};
        cursor: pointer;
        text-align: center;
        width: 130px;
        &:not(:last-child) {
            margin-right: ${theme.spacing(2)}px;
        }
        & > div {
            transition: all 0.2s ease-in-out;
            width: 70px;
            height: 70px;
            border-radius: 100%;
            border: 3px solid ${theme.palette.neutral.white};
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${theme.palette.primary.main};
            margin-bottom: ${theme.spacing(2)}px;
            & > svg {
                width: 32px;
                height: 32px;
                & > path {
                    fill: ${theme.palette.neutral.white};
                }
            }
        }
        &:hover {
            & > div {
                background: ${theme.palette.primary.light};
            }
        }
    `}
  ${({ theme, active }) =>
    active &&
    `
        & > div {
            border: 3px solid ${theme.palette.primary.main};
            background: ${theme.palette.neutral.white};
            & > svg {
                width: 32px;
                height: 32px;
                & > path {
                    fill: ${theme.palette.primary.main};
                }
            }
        }
        &:hover {
            & > div {
                border: 3px solid ${theme.palette.primary.main};
                background: ${theme.palette.neutral.white};
                & > svg {
                    width: 32px;
                    height: 32px;
                    & > path {
                        fill: ${theme.palette.primary.main};
                    }
                }
            }
        }
    `}
`
