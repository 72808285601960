import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Wrapper from "./layout/Wrapper"
import Box from "@material-ui/core/Box"
import Grid from "./Grid"
import Block from "./Block"
import Divider from "./Divider"
import { mapSpacingToDiv } from "../utils/mappers"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Section({ data }) {
  const { __typename } = data

  if (__typename === "ContentfulSection1Col") {
    return <Section1Col data={data} />
  }

  return <Section2Col data={data} />
}

const Section1Col = ({ data }) => {
  const {
    id,
    anchorIdentifier,
    background,
    backgroundImage,
    block,
    spacingTop,
    spacingBottom,
    borderBottom,
    hideOnSmallScreen,
    fullScreen,
  } = data
  return (
    <Identifier id={anchorIdentifier ? anchorIdentifier : id}>
      <SectionContainer
        pt={mapSpacingToDiv(spacingTop)}
        pb={mapSpacingToDiv(spacingBottom)}
        position="relative"
        background={background}
        data-hideonsmallscreen={hideOnSmallScreen}
      >
        <Wrapper fullScreen={fullScreen}>
          <Block data={block} center="center" onecol />
        </Wrapper>
        {borderBottom && <Divider />}
        {backgroundImage && (
          <BackgroundImage
            image={getImage(backgroundImage)}
            alt={`Background for ${anchorIdentifier}`}
          />
        )}
      </SectionContainer>
    </Identifier>
  )
}

const Section2Col = ({ data }) => {
  const {
    id,
    anchorIdentifier,
    background,
    backgroundImage,
    blocks,
    spacing,
    borderBottom,
  } = data
  return (
    <Identifier id={anchorIdentifier ? anchorIdentifier : id}>
      <SectionContainer
        py={mapSpacingToDiv(spacing)}
        position="relative"
        background={background}
      >
        <Wrapper>
          <Box>
            <Grid container spacing={6}>
              {blocks &&
                blocks.map((block, index) => {
                  const swapMediaOnSmallerSreens =
                    block.__typename === "ContentfulMedia" && index === 0
                  return (
                    <Flex
                      key={index}
                      item
                      xs={12}
                      md={6}
                      index={swapMediaOnSmallerSreens ? index + 2 : index}
                    >
                      <Block key={index} data={block} twoCol />
                    </Flex>
                  )
                })}
            </Grid>
          </Box>
        </Wrapper>
        {borderBottom && <Divider />}
        {backgroundImage && (
          <BackgroundImage
            fluid={backgroundImage.fluid}
            alt={`Background for ${anchorIdentifier}`}
          />
        )}
      </SectionContainer>
    </Identifier>
  )
}

const Flex = styled(Grid)`
  display: flex;
  align-items: center;
  ${({ theme, index }) =>
    index &&
    `
        ${theme.breakpoints.down("sm")} {
            order: ${index};
        }
    `}
`

const Identifier = styled(Box)`
  height: 100%;
`

const SectionContainer = styled(Box)`
  position: relative;
  ${({ theme, background }) => {
    switch (background) {
      case "primary_gradient":
        return `
                    background: rgb(0,178,197);
                    background: linear-gradient(315deg, rgba(0,178,197,0.9) 0%, rgba(0,202,223,0.9) 100%);
                `
      case "light_blue_gradient":
        return `
                    background: rgb(165,235,245);
                    background: linear-gradient(315deg, rgba(165,235,245,0.9) 0%, rgba(210,245,250,0.9) 100%);
                `
      case "light_blue_fill":
        return `
                    background: rgba(165,235,245,0.8);
                `
      case "light_blue_gradient_top_down":
        return `
                    background: rgb(165,235,245);
                    background: linear-gradient(180deg, rgba(165,235,245,0.9) 0%, rgba(255,255,255,1) 100%);
                `
      case "light_blue_gradient_bottom_up":
        return `
                    background: rgb(165,235,245);
                    background: linear-gradient(0deg, rgba(165,235,245,0.9) 0%, rgba(255,255,255,1) 100%);
                `
      case "silver_gradient_top_down":
        return `
                    background: rgb(235,237,245);
                    background: linear-gradient(180deg, ${theme.palette.lights.offwhite} 0%, rgba(255,255,255,1) 100%);
                `
      case "silver_gradient_bottom_up":
        return `
                    background: rgb(235,237,245);
                    background: linear-gradient(0deg, ${theme.palette.lights.offwhite} 0%, rgba(255,255,255,1) 100%);
                `
      case "silver_gradient":
        return `
                    background: rgb(235,237,245);
                    background: linear-gradient(315deg, rgba(235,237,245,0.9) 0%, rgba(255,255,255,0.9) 100%);
                `
      case "none":
      default:
        return `background: transparent;`
    }
  }}
  ${(props) =>
    props["data-hideonsmallscreen"] &&
    `
        ${props.theme.breakpoints.down("sm")} {
            display: none;
        }
    `}
`

const BackgroundImage = styled(GatsbyImage)`
  position: absolute !important;
  ${({ theme }) => `
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        & > img {
            object-fit: cover !important;
            object-position: 0% 0% !important;
            font-family: "object-fit: cover !important; object-position: 0% 0% !important;";
        }
        display: none;
        ${theme.breakpoints.up("md")} {
            display: block;
        }
    `}
`

Block.propTypes = {
  data: PropTypes.object.isRequired,
}
