import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { ThemeProvider } from "styled-components"
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles"
import theme from "../styles/theme/index.js"
import GlobalCss from "../styles/theme/global"
import Hero from "../components/Hero"
import Section from "../components/Section"
import SEO from "../components/SEO"
import NavbarCustom from "../components/NavbarCustom"

const MasterPage = ({ data, location, pageContext }) => {
  const { metadata, hero, sections } = data.contentfulPage
  const { pathname } = pageContext
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0)
    }
  }, [location])
  return (
    <>
      <SEO data={metadata} canonicalUrl={canonicalUrl} />
      <StylesProvider>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <GlobalCss />
            <Layout
              hasHero={
                hero ||
                pathname === "resources" ||
                location.pathname.includes("careers")
              }
            >
              {hero && <Hero data={hero} />}
              {(pathname === "resources" ||
                location.pathname.includes("careers")) && (
                <NavbarCustom resources />
              )}
              {location.pathname.includes("blog") && <NavbarCustom blog />}
              {sections &&
                sections.map((section, index) => (
                  <Section key={index} data={section} />
                ))}
            </Layout>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  )
}

export const query = graphql`
  query MasterPage($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulPage(id: { eq: $id }) {
      id
      metadata {
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          file {
            url
          }
        }
      }
      hero {
        ctaBlock {
          title
          titleMarkup
          description {
            raw
          }
          overline
          cta {
            text
            variant
            colour
            gtagEventId
            reference {
              __typename
              ... on ContentfulExternalLink {
                __typename
                linkText
                linkRef
              }
              ... on ContentfulPage {
                __typename
                slug
              }
              ... on ContentfulSection1Col {
                __typename
                anchorIdentifier
              }
              ... on ContentfulSection2Col {
                __typename
                anchorIdentifier
              }
            }
          }
        }
        media {
          media {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            title
          }
          polariod
          maxWidth
        }
        mediaSize
        background
        banner {
          content {
            title
            images {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
              title
            }
          }
        }
        video
      }
      sections {
        ... on ContentfulSection1Col {
          __typename
          id
          anchorIdentifier
          background
          spacingTop
          spacingBottom
          borderBottom
          hideOnSmallScreen
          fullScreen
          backgroundImage {
            gatsbyImageData(layout: FULL_WIDTH)
            title
          }
          block {
            __typename
            ... on ContentfulFlowchart {
              flowchartSteps {
                title
                description {
                  raw
                }
              }
            }
            ... on ContentfulCustomReactForm {
              title
              form
            }
            ... on ContentfulContactCard {
              title
              googleMapsSrc {
                googleMapsSrc
              }
              contactForm {
                formId
              }
              address
              phoneNumber
              emails {
                raw
              }
            }
            ... on ContentfulGrid {
              numberOfColumns
              items {
                contract
                title
                location
                link {
                  __typename
                  linkRef
                  linkText
                }
              }
            }
            ... on ContentfulHubspotFormEmbed {
              formId
            }
            ... on ContentfulCaseStudy {
              companyName
              companySize
              industrySector
              favouriteFeature
              caseStudyBody {
                raw
              }
              reviewQuote {
                reviewQuote
              }
              companyLogo {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                title
              }
            }
            ... on ContentfulReviewCarousel {
              style
              reverse
              carouselSlides {
                __typename
                ... on ContentfulFeatureCard {
                  title
                  description {
                    raw
                  }
                  icon {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                    title
                  }
                  author {
                    name
                    occupation
                    image {
                      gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                      svg {
                        content
                      }
                    }
                  }
                  review
                }
                ... on ContentfulBanner2Col {
                  smallerSlide {
                    ... on ContentfulCtaBlock {
                      title
                      titleMarkup
                      description {
                        raw
                      }
                      cta {
                        text
                        variant
                        colour
                        gtagEventId
                        reference {
                          __typename
                          ... on ContentfulExternalLink {
                            __typename
                            linkText
                            linkRef
                          }
                          ... on ContentfulPage {
                            __typename
                            slug
                          }
                          ... on ContentfulSection1Col {
                            __typename
                            anchorIdentifier
                          }
                          ... on ContentfulSection2Col {
                            __typename
                            anchorIdentifier
                          }
                        }
                      }
                    }
                    ... on ContentfulMedia {
                      media {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                        title
                      }
                      polariod
                      maxWidth
                    }
                  }
                  largerSlide {
                    title
                    description {
                      raw
                    }
                    icon {
                      gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                      title
                    }
                    author {
                      name
                      occupation
                    }
                    review
                  }
                }
                ... on ContentfulBanner3Col {
                  slides {
                    ... on ContentfulCtaBlock {
                      title
                      titleMarkup
                      description {
                        raw
                      }
                      cta {
                        text
                        variant
                        colour
                        gtagEventId
                        reference {
                          __typename
                          ... on ContentfulExternalLink {
                            __typename
                            linkText
                            linkRef
                          }
                          ... on ContentfulPage {
                            __typename
                            slug
                          }
                          ... on ContentfulSection1Col {
                            __typename
                            anchorIdentifier
                          }
                          ... on ContentfulSection2Col {
                            __typename
                            anchorIdentifier
                          }
                        }
                      }
                    }
                    ... on ContentfulMedia {
                      media {
                        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                        title
                      }
                      polariod
                      maxWidth
                    }
                  }
                }
              }
            }
            ... on ContentfulWysiwygRichTextField {
              wysiwygRichTextField {
                raw
              }
            }
            ... on ContentfulVideoCards {
              id
              videoCards {
                title
                description {
                  raw
                }
                media {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
                video
              }
            }
            ... on ContentfulVideoFeatured {
              title
              featuredVideo
            }
            ... on ContentfulVerticalHoverCard {
              cards {
                title
                description {
                  raw
                }
                icon {
                  svg {
                    content
                  }
                }
                postfix
                numericalStat
                media {
                  maxWidth
                  media {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                    title
                  }
                }
                cta {
                  text
                  variant
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      id
                      anchorIdentifier
                      page {
                        slug
                      }
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      id
                      anchorIdentifier
                      page {
                        slug
                      }
                    }
                  }
                }
              }
            }
            ... on ContentfulCtaBlock {
              title
              titleMarkup
              description {
                raw
              }
              overline
              cta {
                text
                variant
                colour
                gtagEventId
                reference {
                  __typename
                  ... on ContentfulExternalLink {
                    __typename
                    linkText
                    linkRef
                  }
                  ... on ContentfulPage {
                    __typename
                    slug
                  }
                  ... on ContentfulSection1Col {
                    __typename
                    id
                    anchorIdentifier
                    page {
                      slug
                    }
                  }
                  ... on ContentfulSection2Col {
                    __typename
                    id
                    anchorIdentifier
                    page {
                      slug
                    }
                  }
                }
              }
            }
            ... on ContentfulMedia {
              id
              polariod
              maxWidth
              media {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                title
              }
            }
            ... on ContentfulFeatureCards {
              variant
              alignment
              cards {
                title
                description {
                  raw
                }
                icon {
                  svg {
                    content
                  }
                }
                postfix
                numericalStat
                media {
                  media {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                    title
                  }
                }
                cta {
                  text
                  variant
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      id
                      anchorIdentifier
                      page {
                        slug
                      }
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      id
                      anchorIdentifier
                      page {
                        slug
                      }
                    }
                  }
                }
              }
            }
            ... on ContentfulGridWithCta {
              id
              ctaBlock {
                title
                titleMarkup
                description {
                  raw
                }
                overline
                cta {
                  text
                  variant
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      id
                      anchorIdentifier
                      page {
                        slug
                      }
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      id
                      anchorIdentifier
                      page {
                        slug
                      }
                    }
                  }
                }
              }
              grid {
                __typename
                ... on ContentfulNavlinkDropdownColumn {
                  id
                  links {
                    __typename
                    linkText
                    linkReference {
                      __typename
                      ... on ContentfulExternalLink {
                        __typename
                        linkText
                        linkRef
                        icon {
                          svg {
                            content
                          }
                        }
                      }
                      ... on ContentfulPage {
                        __typename
                        slug
                        icon {
                          svg {
                            content
                          }
                        }
                      }
                      ... on ContentfulSection1Col {
                        __typename
                        id
                        anchorIdentifier
                        page {
                          slug
                        }
                      }
                      ... on ContentfulSection2Col {
                        __typename
                        id
                        anchorIdentifier
                        page {
                          slug
                        }
                      }
                    }
                    description {
                      description
                    }
                  }
                }
                ... on ContentfulAccordion {
                  accordionItems {
                    title
                    description {
                      raw
                    }
                    cta {
                      text
                      gtagEventId
                      reference {
                        __typename
                        ... on ContentfulExternalLink {
                          __typename
                          linkText
                          linkRef
                        }
                        ... on ContentfulPage {
                          __typename
                          slug
                        }
                        ... on ContentfulSection1Col {
                          __typename
                          anchorIdentifier
                        }
                        ... on ContentfulSection2Col {
                          __typename
                          anchorIdentifier
                        }
                      }
                      colour
                      variant
                    }
                  }
                }
              }
            }
            ... on ContentfulImageGrid {
              id
              title
              images {
                svg {
                  content
                }
              }
              stars
            }
            ... on ContentfulCarousel {
              slides {
                title
                description {
                  raw
                }
                cta {
                  text
                  variant
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                }
                media {
                  media {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                    title
                  }
                }
                icon {
                  svg {
                    content
                  }
                }
                review
              }
            }
            ... on ContentfulAccordion {
              accordionItems {
                title
                description {
                  raw
                }
                cta {
                  text
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                  colour
                  variant
                }
              }
            }
            ... on ContentfulPricingColumns {
              id
              primaryColumnsTitle
              primaryColumns {
                id
                variant
                columnTitle
                price
                pricePerMonthAnnual
                priceDescription
                priceDescriptionAnnual
                columnDescription
                columnDescriptionAnnual
                ctaButton {
                  variant
                  text
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                }
                ctaButtonAnnual {
                  variant
                  text
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                }
                listTitle
                list
              }
              subPricing {
                id
                variant
                columnTitle
                price
                pricePerMonthAnnual
                priceDescription
                priceDescriptionAnnual
                columnDescription
                columnDescriptionAnnual
                ctaButton {
                  variant
                  text
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                }
                ctaButtonAnnual {
                  variant
                  text
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                }
                listTitle
                list
              }
              featuresTableTitle
              featuresTable {
                title
                columnOneTitle
                columnTwoTitle
                columnThreeTitle
                featureItems {
                  title
                  columnOneBoolean
                  columnOneNote
                  columnTwoBoolean
                  columnTwoNote
                  columnThreeBoolean
                  columnThreeNote
                }
              }
              secondaryColumnsTitle
              secondaryColumns {
                title
                icon {
                  svg {
                    content
                  }
                }
                description {
                  raw
                }
              }
            }
          }
        }
        ... on ContentfulSection2Col {
          __typename
          id
          anchorIdentifier
          background
          spacing
          borderBottom
          blocks {
            __typename
            ... on ContentfulFlowchart {
              flowchartSteps {
                title
                description {
                  raw
                }
              }
            }
            ... on ContentfulVideoFeatured {
              title
              featuredVideo
            }
            ... on ContentfulCtaBlock {
              title
              titleMarkup
              description {
                raw
              }
              overline
              cta {
                text
                variant
                colour
                gtagEventId
                reference {
                  __typename
                  ... on ContentfulExternalLink {
                    __typename
                    linkText
                    linkRef
                  }
                  ... on ContentfulPage {
                    __typename
                    slug
                  }
                  ... on ContentfulSection1Col {
                    __typename
                    id
                    anchorIdentifier
                    page {
                      slug
                    }
                  }
                  ... on ContentfulSection2Col {
                    __typename
                    id
                    anchorIdentifier
                    page {
                      slug
                    }
                  }
                }
              }
            }
            ... on ContentfulMedia {
              id
              polariod
              maxWidth
              media {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                title
              }
            }
            ... on ContentfulCarousel {
              slides {
                title
                description {
                  raw
                }
                cta {
                  text
                  variant
                  colour
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                }
                media {
                  media {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                    title
                  }
                }
                icon {
                  svg {
                    content
                  }
                }
                review
              }
            }
            ... on ContentfulAccordion {
              accordionItems {
                title
                description {
                  raw
                }
                cta {
                  text
                  gtagEventId
                  reference {
                    __typename
                    ... on ContentfulExternalLink {
                      __typename
                      linkText
                      linkRef
                    }
                    ... on ContentfulPage {
                      __typename
                      slug
                    }
                    ... on ContentfulSection1Col {
                      __typename
                      anchorIdentifier
                    }
                    ... on ContentfulSection2Col {
                      __typename
                      anchorIdentifier
                    }
                  }
                  colour
                  variant
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MasterPage
