import React from "react"
import loadable from "@loadable/component"
const FeatureCards = loadable(() => import("./FeatureCards"))
const GridWithCta = loadable(() => import("./GridWithCta"))
const ImageGrid = loadable(() => import("./ImageGrid"))
const CtaBlock = loadable(() => import("./CtaBlock"))
const Media = loadable(() => import("./Media"))
const Carousel = loadable(() => import("./Carousel"))
const Accordion = loadable(() => import("./Accordion"))
const PricingColumns = loadable(() => import("./PricingColumns"))
const VerticalHoverCard = loadable(() => import("./VerticalHoverCard"))
const WYSIWYGField = loadable(() => import("./WYSIWYGField"))
const ReviewCarousel = loadable(() => import("./ReviewCarousel"))
const CaseStudy = loadable(() => import("./CaseStudy"))
const VideoCards = loadable(() => import("./VideoCards"))
const HubSpotForm = loadable(() => import("./HubSpotForm"))
const Flowchart = loadable(() => import("./Flowchart"))
const FeaturedVideo = loadable(() => import("./FeaturedVideo"))
const CardGrid = loadable(() => import("./CardGrid"))
const ContactCard = loadable(() => import("./ContactCard"))
const CustomReactForm = loadable(() => import("./CustomReactForm"))

export default function Block(props) {
  const { __typename } = props.data
  switch (__typename) {
    case "ContentfulMedia":
      return <Media {...props} />
    case "ContentfulCtaBlock":
      return <CtaBlock {...props} />
    case "ContentfulFeatureCards":
      return <FeatureCards {...props} />
    case "ContentfulVideoCards":
      return <VideoCards {...props} />
    case "ContentfulVideoFeatured":
      return <FeaturedVideo {...props} />
    case "ContentfulGridWithCta":
      return <GridWithCta {...props} />
    case "ContentfulImageGrid":
      return <ImageGrid {...props} />
    case "ContentfulCarousel":
      return <Carousel {...props} />
    case "ContentfulAccordion":
      return <Accordion {...props} />
    case "ContentfulPricingColumns":
      return <PricingColumns {...props} />
    case "ContentfulVerticalHoverCard":
      return <VerticalHoverCard {...props} />
    case "ContentfulWysiwygRichTextField":
      return <WYSIWYGField {...props} />
    case "ContentfulReviewCarousel":
      return <ReviewCarousel {...props} />
    case "ContentfulCaseStudy":
      return <CaseStudy {...props} />
    case "ContentfulHubspotFormEmbed":
      return <HubSpotForm {...props} />
    case "ContentfulFlowchart":
      return <Flowchart {...props} />
    case "ContentfulGrid":
      return <CardGrid {...props} />
    case "ContentfulContactCard":
      return <ContactCard {...props} />
    case "ContentfulCustomReactForm":
      return <CustomReactForm {...props} />
    default:
      return <div>Block missing</div>
  }
}
